.log-in {
  padding-top: 29px;
}

.log-in a {
  font-size: 15px;
  font-weight: 900;
  letter-spacing: -0.2px;
  margin-right: 35px;
  padding-top: 29px;
  text-decoration: none;
  color: var(--white);
}
