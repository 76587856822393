:root {
  /* --blue: #1f79d2; */
  --blue: #1d3246;
  --white: #ffffff;
  --black: #000000;
  --active-blue: #427da5;
}

@font-face {
  font-family: 'AvenirHeavy';
  src: local('MyFont'), url(./assets/fonts/Avenir-Heavy.ttf) format('truetype');
}
@font-face {
  font-family: 'AvenirLight';
  src: local('AvenirLight'),
    url(./assets/fonts/Avenir-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'AvenirMedium';
  src: local('AvenirMedium'),
    url(./assets/fonts/Avenir-Medium.ttf) format('truetype');
}

:root {
  --font-family-sans-serif: 'AvenirMedium' !important;
  --font-family-monospace: 'AvenirMedium' !important;
}

body {
  margin: 0;
  background-color: var(--black) !important;
  font-family: 'AvenirMedium' !important;
  /* -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
}

html {
  background-color: var(--white) !important;
  font-family: 'AvenirMedium' !important;
}

.preLogin-background {
  background: linear-gradient(
      176deg,
      rgba(0, 0, 0, 0.75) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    url('./assets/images/Login-Banner.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100vh;
}

.swiss .swiss-navbar .bg-black {
  background-color: var(--blue);
  padding: 0.5rem 5.5rem;
}

.swiss .swiss-navbar .navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' version='1.1' width='512' height='512' x='0' y='0' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512' xml:space='preserve' class=''%3E%3Cg%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath d='M492,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20S503.046,236,492,236z' fill='%23ffffff' data-original='%23000000' style='' class=''/%3E%3C/g%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath d='M492,76H20C8.954,76,0,84.954,0,96s8.954,20,20,20h472c11.046,0,20-8.954,20-20S503.046,76,492,76z' fill='%23ffffff' data-original='%23000000' style='' class=''/%3E%3C/g%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath d='M492,396H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20 C512,404.954,503.046,396,492,396z' fill='%23ffffff' data-original='%23000000' style='' class=''/%3E%3C/g%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}

.swiss .swiss-navbar .navbar .navbar-collapse {
  padding-left: 100px;
}

.swiss .swiss-navbar .navbar-nav .nav-link {
  color: var(--white);
  font-weight: 600;
  margin-right: 75px;
  position: relative;
  padding-left: 0;
  padding-right: 0;
  transition: 0.3s;
}

.swiss .swiss-navbar .navbar-nav .nav-link:focus {
  color: var(--active-blue);
}

.swiss .swiss-navbar .navbar-nav .nav-link:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 5px;
  left: 0px;
  background-color: var(--white);
  visibility: hidden;
  width: 0px;
  transition: all 0.3s ease-in-out 0s;
}

.swiss .swiss-navbar .navbar-nav .nav-link:hover:before {
  visibility: visible;
  width: 100%;
}

.swiss .hero {
  background: linear-gradient(
      176deg,
      rgba(0, 0, 0, 0.75) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    url(./assets/images/hero.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: calc(100vh - 76px);
  margin-top: 76px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 125px;
}

.swiss .hero .hero-content {
  width: 54.5%;
}

.swiss .hero .hero-content h1 {
  font-weight: 600;
  color: var(--black);
  font-size: 3.3rem;
}

.swiss .hero .hero-content p {
  color: var(--black);
  font-size: 21px;
  margin: 40px 0;
  width: 75%;
}

.swiss .hero .hero-content a {
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.5rem 1.5rem;
  font-size: 15px;
  background-color: var(--blue);
  border-color: var(--blue);
  color: var(--white);
}

.swiss .hero a:hover,
.swiss .hero a:focus,
.swiss .hero a:active {
  background-color: var(--active-blue) !important;
  border-color: var(--active-blue) !important;
  color: var(--white) !important;
  box-shadow: none !important;
}

.swiss .stream {
  background-color: var(--black);
  color: var(--white);
  /* padding: 50px 0; */
}

.swiss .stream .courses-heading h1 {
  margin-left: 120px;
  margin-bottom: 4rem;
  margin-top: 4rem;
  color: var(--black);
  font-size: 3.3rem;
  position: relative;
}

.swiss .stream .courses-heading h1::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 200px;
  height: 3px;
  background-color: var(--black);
}

.swiss .stream .courses-heading h3 {
  margin-left: 120px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  color: gray;
  font-size: 2.5rem;
}

.swiss .courses p,
.swiss .stream p {
  font-size: 19px;
}

.swiss .courses h2,
.swiss .stream h2 {
  max-width: 380px;
}

.swiss .stream .small-text {
  margin-top: 2.5rem;
}

.swiss .stream .swiper-container {
  width: 100%;
  height: 100%;
}

.swiss .stream .swiper-container .swiper-button-next,
.swiss .stream .swiper-container .swiper-button-prev {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 40px;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  transform: translateY(-50%);
  margin-top: 0;
  color: var(--white);
}

.swiss .stream .swiper-container .swiper-button-next {
  right: 0;
}

.swiss .stream .swiper-container .swiper-button-prev {
  left: 0;
}

.swiss .stream .swiper-container .swiper-button-next:after,
.swiss .stream .swiper-container .swiper-button-prev:after {
  font-size: 50px;
}

.swiss .stream .swiper-container {
  width: 100%;
  height: 100%;
}
.swiss .stream .swiper-container .swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Update: Removed the Flex property - IE FIX */
}

.swiper-wrapper li:last-of-type {
  margin-right: 100px;
}
.swiper-wrapper {
  align-items: center;
}
/* .swiss .stream .swiper-container .swiper-wrapper {
  padding: 20px 0;
} */

/* .swiss .stream .swiper-container .swiper-slide {
  transition: 250ms all;

} */
/* .swiss .stream .swiper-container .swiper-slide:hover {
  transform: scale(1.2);
  z-index: 1;
} */

/* .swiss .stream .swiper-container .swiper-slide:first-child:hover {
  margin: 0 40px;
}

.swiss .stream .swiper-container .swiper-slide:last-child:hover {
  margin: 0 -40px;
} */

.swiss .courses .btn,
.swiss .stream .btn {
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 1rem 1.5rem;
  font-size: 14px;
  background-color: var(--blue);
  border-color: var(--blue);
  color: var(--white);
}

.swiss .courses .btn:hover,
.swiss .stream .btn:hover {
  background-color: var(--blue);
  border-color: var(--blue);
}

.swiss .courses .btn:active,
.swiss .stream .btn:active {
  background-color: var(--active-blue) !important;
  border-color: var(--active-blue) !important;
  color: var(--white) !important;
}

.swiss .courses .btn:focus,
.swiss .stream .btn:focus {
  box-shadow: none;
}

.swiss .courses button.btn:focus-visible,
.swiss .stream button.btn:focus-visible {
  outline: none;
}

.swiss .app {
  background-color: var(--white);
  /* margin: 20px; */
  color: var(--black);
  padding: 75px 0;
  border: 20px solid;
}

.swiss .app p {
  font-size: 19px;
}

.swiss .app .mobile {
  margin-left: 100px;
}

.swiss .community {
  background-color: var(--blue);
  padding: 75px 0;
  color: var(--black);
}

.swiss .community p {
  font-size: 18px;
}

.swiss .subscribe div.row {
  justify-content: space-evenly;
}

.swiss .subscribe {
  background: linear-gradient(
      176deg,
      rgba(0, 0, 0, 0.75) 0%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    url(./assets/images/hero.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 250px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 20px; */
  color: var(--black);
  border: 20px solid;
}

.swiss .subscribe input {
  height: 4rem;
}

.swiss .subscribe input:focus {
  box-shadow: none;
  z-index: 0;
}

.swiss .subscribe ::-webkit-input-placeholder,
.swiss .support ::-webkit-input-placeholder,
.swiss .booking ::-webkit-input-placeholder,
.swiss .scholarship-app ::-webkit-input-placeholder {
  /* Edge */
  color: #b7b7b7;
}

.swiss .subscribe :-ms-input-placeholder,
.swiss .support :-ms-input-placeholder,
.swiss .booking :-ms-input-placeholder,
.swiss .scholarship-app :-ms-input-placeholder {
  /* Internet Explorer */
  color: #b7b7b7;
  font-size: 14px;
}

.swiss .subscribe ::placeholder,
.swiss .support ::placeholder,
.swiss .booking ::placeholder,
.swiss .scholarship-app ::placeholder {
  color: #b7b7b7;
  font-size: 14px;
}

.swiss .subscribe .input-group input {
  border-color: var(--black);
  font-size: 14px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.swiss .subscribe .button {
  background-color: var(--blue);
  border-color: var(--blue);
  font-weight: bold;
  color: var(--white);
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0.375rem 3rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
  transition: 0.3s;
}

.swiss .subscribe .button:hover {
  background-color: var(--active-blue);
  border-color: var(--active-blue);
}

.swiss .subscribe .input-group .input-group-prepend .input-group-text {
  background-color: var(--blue);
  border-color: var(--blue);
  font-weight: bold;
  color: var(--white);
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0.375rem 3rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.swiss .support .support-header,
.swiss .privacy .privacy-header,
.swiss .booking .booking-header,
.swiss .scholarship-app .scholarship-app-header {
  background: linear-gradient(
      176deg,
      rgba(0, 0, 0, 0.75) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    url(./assets/images/hero.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 400px;
  margin-top: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.swiss .support .support-header h1,
.swiss .privacy .privacy-header h1,
.swiss .booking .booking-header h1,
.swiss .scholarship-app .scholarship-app-header h1 {
  font-weight: 600;
  color: var(--black);
  font-size: 3.3rem;
  margin-bottom: 0;
}

.swiss .support .support-header p,
.swiss .privacy .privacy-header p {
  color: var(--black);
  font-size: 22px;
  margin: 20px 0;
}

.swiss .support .support-info,
.swiss .privacy .privacy-info,
.swiss .booking .booking-info,
.swiss .scholarship-app .scholarship-app-info {
  height: 400px;
  background-color: var(--black);
  position: relative;
}

.swiss .booking .booking-info,
.swiss .scholarship-app .scholarship-app-info {
  height: auto;
}

.swiss .support .support-info .info-form,
.swiss .privacy .privacy-info .info-area,
.swiss .booking .booking-info .info-form,
.swiss .scholarship-app .scholarship-app-info .info-form {
  background-color: var(--black);
  padding: 30px;
  border-radius: 0.25rem;
  max-width: 1200px;
  width: 100%;
  position: absolute;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
  /* border: 1px solid #dee2e6; */
  box-shadow: 0 0rem 1rem rgba(0, 0, 0, 0.05);
}

.swiss .booking .booking-info .info-form,
.swiss .scholarship-app .scholarship-app-info .info-form {
  position: relative;
}

.swiss .support .support-info .info-form .bd-r {
  border-right: 1px solid #dee2e6;
}

.swiss .support .support-info .info-form h1,
.swiss .privacy .privacy-info .info-area h1 {
  color: var(--blue);
}

.swiss .support .support-info .info-form h5,
.swiss .booking .booking-info .info-form h5,
.swiss .scholarship-app .scholarship-app-info .info-form h5 {
  font-size: 22px;
  margin: 20px 0;
  color: var(--blue);
}

.swiss .support .support-info .info-form p,
.swiss .booking .booking-info .info-form p,
.swiss .scholarship-app .scholarship-app-info .info-form p {
  font-size: 18px;
  color: var(--white);
}

.swiss .support .support-info .info-form ul li,
.swiss .privacy .privacy-info .info-area ul li {
  padding: 10px 0;
}

.swiss .support .support-info .info-form input:focus,
.swiss .support .support-info .info-form textarea:focus,
.swiss .booking .booking-info .info-form input:focus,
.swiss .booking .booking-info .info-form textarea:focus,
.swiss .scholarship-app .scholarship-app-info .info-form input:focus,
.swiss .scholarship-app .scholarship-app-info .info-form textarea:focus {
  box-shadow: none;
  border-color: var(--blue);
}

.swiss .support .support-info .info-form textarea,
.swiss .booking .booking-info .info-form textarea,
.swiss .scholarship-app .scholarship-app-info .info-form textarea {
  resize: none;
}

.swiss .support .support-info .info-form button,
.swiss .booking .booking-info .info-form button,
.swiss .scholarship-app .scholarship-app-info .info-form button {
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.5rem 1.5rem;
  font-size: 15px;
  background-color: var(--blue);
  border-color: var(--blue);
  color: var(--white);
}

.swiss .support .support-info .info-form button:disabled,
.swiss .booking .booking-info .info-form button:disabled,
.swiss .scholarship-app .scholarship-app-info .info-form button:disabled {
  cursor: not-allowed;
}

.swiss .support .support-info .info-form button:hover,
.swiss .support .support-info .info-form button:active,
.swiss .booking .support-info .info-form button:hover,
.swiss .booking .support-info .info-form button:active,
.swiss .scholarship-app .scholarship-app-info .info-form button:hover,
.swiss .scholarship-app .scholarship-app-info .info-form button:active {
  background-color: var(--blue);
  border-color: var(--blue);
  color: var(--white);
}

.swiss .support .support-info .info-form button:focus,
.swiss .support .support-info .info-form button:active:focus,
.swiss .booking .booking-info .info-form button:focus,
.swiss .booking .booking-info .info-form button:active:focus,
.swiss .scholarship-app .scholarship-app-info .info-form button:focus,
.swiss .scholarship-app .scholarship-app-info .info-form button:active:focus {
  box-shadow: none;
}

.swiss .support .spinner-border,
.swiss .booking .spinner-border,
.swiss .scholarship-app .spinner-border {
  width: 1rem;
  height: 1rem;
  color: var(--white);
  margin-left: 0.5rem;
}

.swiss .support .support-info .info-form p.text-swiss {
  color: var(--blue);
}

.swiss .privacy .privacy-info {
  height: auto;
}

.swiss .privacy .privacy-info .info-area {
  position: relative;
}

.swiss .privacy .privacy-info .info-area h6 {
  font-weight: bold;
  margin: 20px 0 40px 0;
}

.swiss .privacy .privacy-info .info-area a {
  color: var(--blue);
  cursor: pointer;
}

.swiss .privacy .privacy-info .info-area ul {
  list-style-type: square;
}

.swiss .privacy .privacy-info .info-area .table-contents {
  padding-left: 0;
  list-style-type: none;
}

.swiss .privacy .privacy-info .info-area .table-content-area {
  margin: 50px 0;
}

.swiss .courses {
  width: 100%;
  margin: 76px 0px 40px 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 125px;
  padding-top: 100px;
  color: var(--black);
}

.swiss .courses .hero-content h1 {
  font-weight: 600;
  color: var(--black);
  font-size: 3.3rem;
}

.swiss .courses .hero-content p {
  color: var(--black);
  font-size: 21px;
  margin: 20px 0;
}

.swiss .footer {
  padding: 50px 0;
  background-color: var(--blue);
  color: var(--white);
  padding-left: 125px;
  padding-right: 125px;
}

.swiss .footer h6 {
  text-transform: uppercase;
  margin-bottom: 2.5rem;
}

.swiss .footer h6:last-child {
  margin-bottom: 0;
  color: var(--white);
}

.swiss .footer .info {
  margin-bottom: 2.5rem;
}

.swiss .footer .info h6 {
  margin-bottom: 1rem;
  text-transform: none;
  line-height: 1.5;
  color: var(--white);
}

.swiss .footer .info h6.heading {
  color: var(--white);
  margin-bottom: 0.25rem;
}

.swiss .footer .social-links a {
  margin-right: 50px;
  color: var(--white);
}
.swiss .footer .social-links a:hover {
  text-decoration: none;
  color: var(--active-blue);
}

.swiss .footer .social-links a:last-child {
  margin-right: 0;
}

.swiss .pt-100 {
  padding-top: 100px;
}

.swiss .spinner-border {
  width: 6rem;
  height: 6rem;
  color: var(--blue);
}

.swiss .back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 30px;
  bottom: 30px;
  z-index: 996;
  background: var(--blue);
  width: 40px;
  height: 40px;
  border-radius: 5px;
  transition: all 0.4s;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.swiss .back-to-top i {
  font-size: 28px;
  color: var(--white);
  line-height: 0;
}

.swiss .back-to-top.active {
  visibility: visible;
  opacity: 1;
}

.single-course-modal.modal .my-6 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.single-course-modal.modal .modal-dialog {
  max-width: 1000px;
}

.single-course-modal.modal .modal-content {
  background-color: var(--white);
  box-shadow: 0 0 1rem rgb(255 255 255 / 15%);
}

.single-course-modal.modal .modal-content .close {
  opacity: 1;
  color: var(--black);
}

.single-course-modal.modal .modal-content .modal-body {
  padding: 0px 80px 80px;
  color: var(--black);
}

.single-course-modal.modal .modal-content .modal-body h6 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
}

.single-course-modal.modal .modal-content .modal-body p {
  font-size: 19px;
}

.single-course-modal.modal .modal-content .modal-body .btn {
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 1rem 1.5rem;
  font-size: 14px;
  background-color: var(--blue);
  border-color: var(--blue);
  color: var(--white);
}

.single-course-modal.modal .modal-content .modal-body .btn:hover {
  background-color: var(--active-blue);
  border-color: var(--active-blue);
}

.single-course-modal.modal .modal-content .modal-body .nav .nav-item {
  background-color: transparent;
  border-color: transparent;
  color: var(--black);
  font-weight: 300;
  border-radius: 0;
  padding: 0.75rem 1rem;
}

.single-course-modal.modal .modal-content .modal-body .nav .nav-item.active {
  font-weight: bold;
  border-bottom: 4px solid var(--black);
}

.single-course-modal.modal .modal-content .modal-body .tab-content {
  padding: 40px 0;
}

.single-course-modal.modal .modal-content .modal-body .tab-content ul {
  padding-left: 20px;
}

/* New CSS */

.noty_type__error {
  background-color: var(--white) !important;
  color: var(--blue) !important;
  border-color: var(--white) !important;
}

.noty_bar {
  margin: 4px 0;
  overflow: hidden;
  position: relative;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.noty_bar .noty_body {
  font-weight: 600 !important;
  padding: 1.07rem 1.25rem !important;
}

.modal-open {
  padding-right: 0px !important;
}

code {
  font-family: 'AvenirMedium';
}

/* Global CSS */

.main-steps {
  font-size: 12px;
  font-weight: 500;
  color: var(--white);
  font-family: 'AvenirMedium';
  margin: 0;
}

.main-heading {
  font-size: 27px;
  font-weight: 900;
  color: var(--white);
  font-family: 'AvenirHeavy';
  margin: 0;
}

.main-heading-paragraph {
  font-size: 13px;
  font-weight: 500;
  color: var(--white);
  font-family: 'AvenirMedium';
  margin: 0;
}

.next-button {
  width: 100%;
  text-align: center;
  margin-top: 50px;
  background: var(--blue) !important;
  color: var(--white);
  padding: 9px 0px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'AvenirHeavy';
}

.next-button h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0.53px;
}

.logout {
  color: var(--blue);
  transition: all ease-in-out 0.5s;
  cursor: pointer;
  margin: 0;
}

.logout:hover {
  color: var(--active-blue);
  transition: all ease-in-out 0.5s;
}

/* Global CSS */

/* Resend Modal CSS */

.resend-modal-content {
  background-color: var(--white) !important;
  border: 1px solid var(--black) !important;
  padding: 0 56px;
}

.resend-modal-body {
  padding: 0 !important;
}

.resend-modal-body .buttons {
  text-align: right;
  width: 100%;
  margin-bottom: 50px;
}

.resend-modal-body .buttons .contact-btn {
  background: transparent;
  border: none;
  color: var(--blue);
  margin-right: 26px;
}

.resend-modal-body .buttons .ok-btn {
  /* width: 120px; */
  padding: 10px 13px;
  background: var(--blue);
  border: 1px solid var(--blue);
  font-size: 16px;
  font-weight: 900;
  font-family: 'AvenirHeavy';
  border-radius: 4px;
  color: var(--white);
  text-decoration: none;
}

/* Resend Modal CSS */

.forgot-password {
  color: var(--white);
  transition: 200ms all ease-in-out;
  font-weight: 900;
  font-family: 'AvenirHeavy';
}

.forgot-password:hover {
  /* transition-delay: 300ms; */
  transition: 200ms all ease-in-out;
  color: var(--active-blue);
  text-decoration: none;
}

.email-input:focus {
  /* border: solid 1px #353535; */
  outline: none;
}

.custom-control-input {
  width: 18px !important;
  height: 18px !important;
}

.custom-control {
  padding-left: 34px !important;
}

/* .custom-control-input:focus ~ .custom-control-label::before {
  border-color: transparent !important;
  box-shadow: none !important;
} */

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--blue) !important;
  border-color: var(--blue) !important;
  box-shadow: none !important;
}

.custom-control-input:checked ~ .custom-control-label::after {
  /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 8 8'%3e%3cpath fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e") !important; */
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0 c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7 C514.5,101.703,514.499,85.494,504.502,75.496z'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E") !important; */
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Capa_1' x='0px' y='0px' width='45.701px' height='45.7px' viewBox='0 0 45.701 45.7' style='enable-background:new 0 0 45.701 45.7;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M20.687,38.332c-2.072,2.072-5.434,2.072-7.505,0L1.554,26.704c-2.072-2.071-2.072-5.433,0-7.504 c2.071-2.072,5.433-2.072,7.505,0l6.928,6.927c0.523,0.522,1.372,0.522,1.896,0L36.642,7.368c2.071-2.072,5.433-2.072,7.505,0 c0.995,0.995,1.554,2.345,1.554,3.752c0,1.407-0.559,2.757-1.554,3.752L20.687,38.332z'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E") !important; */
}

.custom-control-label::before {
  top: 0px !important;
  left: -34px !important;
  width: 18px !important;
  height: 18px !important;
}

.custom-control-label::after {
  top: 0px !important;
  left: -34px !important;
  width: 18px !important;
  height: 18px !important;
  cursor: pointer;
}

.label-recieve-updates {
  position: absolute;
  width: 288px;
  font-size: 12px;
}

.label-use-default {
  position: absolute !important;
  width: 288px;
  font-weight: 600 !important;

  font-size: 12px !important;
  text-transform: initial !important;
  color: #212529 !important;
}

.content {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/* Card Detail checkboxes */
.custom-control-checkbox-text {
  padding: 12px 6px 15px 16px;
  border-radius: 4px;
  background-color: #2d2d2d;
  color: var(--blue);
  align-items: center;
  display: flex;
  padding-left: 17px !important;
  padding-right: 24px;
  width: 100%;
  height: 68px;
}

.custom-control-input-round {
  width: 24px !important;
  height: 24px !important;
}

.custom-control-label-round {
  width: 100%;
}

.custom-control-label-round span {
  font-size: 16px;
  font-family: 'AvenirMedium';
  letter-spacing: 0.53px;
  line-height: 22px;
}

.custom-control-label-round::after {
  top: 50% !important;
  transform: translateY(-50%);
  width: 24px !important;
  height: 24px !important;
  cursor: pointer;
  border-radius: 30px !important;
}
.custom-control-label-round::before {
  top: 50% !important;
  transform: translateY(-50%);
  width: 24px !important;
  height: 24px !important;
  cursor: pointer;
  background-color: var(--white) !important;
  border-radius: 30px !important;
}

.custom-control-input-round:focus ~ .custom-control-label-round::before {
  border-color: #797979 !important;
  box-shadow: none !important;
}

.custom-control-input-round:checked ~ .custom-control-label-round::before {
  background-color: var(--blue) !important;
  border-color: var(--blue) !important;
  box-shadow: none !important;
}

.custom-control-input-round:checked ~ .custom-control-label-round::after {
  /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 8 8'%3e%3cpath fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e") !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0 c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7 C514.5,101.703,514.499,85.494,504.502,75.496z'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E") !important; */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Capa_1' x='0px' y='0px' width='45.701px' height='45.7px' viewBox='0 0 45.701 45.7' style='enable-background:new 0 0 45.701 45.7;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M20.687,38.332c-2.072,2.072-5.434,2.072-7.505,0L1.554,26.704c-2.072-2.071-2.072-5.433,0-7.504 c2.071-2.072,5.433-2.072,7.505,0l6.928,6.927c0.523,0.522,1.372,0.522,1.896,0L36.642,7.368c2.071-2.072,5.433-2.072,7.505,0 c0.995,0.995,1.554,2.345,1.554,3.752c0,1.407-0.559,2.757-1.554,3.752L20.687,38.332z'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E") !important;
}

.custom-control.custom-checkbox {
  padding-left: 0;
}

label.custom-control-label-round {
  position: relative;
  padding-right: 1.5rem;
}

label.custom-control-label-round::before,
label.custom-control-label-round::after {
  right: 0;
  left: auto !important;
}

/* For Stripe */
.card-number {
  background-color: var(--white);
  padding: 10px 20px 11px;
  border-radius: 5px;
  margin-block-end: 5px;
  /* height: 50px; */
  width: 100%;
  /* border: 1px solid #afafaf; */
  /* box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07); */
}

.card-number-payment {
  border: 1px solid #e2e2e2;
  background-color: #e2e2e2;
  color: var(--blue);
}
.next-button-pay {
  width: 100%;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 10px;
  background: var(--blue);
  padding: 9px 0px;
  border-radius: 4px;
  cursor: pointer;
  color: var(--white);
  font-family: 'AvenirHeavy';
}
.pay-button {
  padding: 0.7rem 2rem;
  width: 100%;
  margin: 1rem 0;
  color: var(--blue);
  font-weight: bold;
  font-size: medium;
  background-color: #556cd6;
  border: 0;
  border-radius: 5px;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  transition: box-shadow 500ms;
  cursor: pointer;
}
.pay-button:disabled {
  background-color: #afafaf;
  box-shadow: none;
  cursor: default;
}
.pay-button:disabled:hover {
  box-shadow: none;
}
.pay-button:hover {
  box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
}

.card-label {
  font-size: 12px;
  letter-spacing: -0.16px;
  color: #f7f7f7;
  font-family: 'AvenirMedium';
  margin-block-end: 5px;
  align-self: flex-start;
}

/* Payment Box */

.modal .modal-content .payment-box {
  height: 5rem;
  border: 1px solid #b9b9b9;
  background-color: transparent;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal .modal-content .payment-box .expiry-info {
  display: flex;
  align-items: center;
}

.modal .modal-content .payment-box .expiry-info p {
  font-size: 12px;
  margin-bottom: 2px;
  font-weight: normal;
  text-transform: initial;
}

.modal .modal-content .payment-box .arrow-down {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='15' height='15' viewBox='0 0 490.688 490.688' style='enable-background:new 0 0 490.688 490.688;' xml:space='preserve'%3E%3Cpath style='fill:%23FFC107;' d='M472.328,120.529L245.213,347.665L18.098,120.529c-4.237-4.093-10.99-3.975-15.083,0.262 c-3.992,4.134-3.992,10.687,0,14.82l234.667,234.667c4.165,4.164,10.917,4.164,15.083,0l234.667-234.667 c4.237-4.093,4.354-10.845,0.262-15.083c-4.093-4.237-10.845-4.354-15.083-0.262c-0.089,0.086-0.176,0.173-0.262,0.262 L472.328,120.529z'/%3E%3Cpath d='M245.213,373.415c-2.831,0.005-5.548-1.115-7.552-3.115L2.994,135.633c-4.093-4.237-3.975-10.99,0.262-15.083 c4.134-3.992,10.687-3.992,14.82,0l227.136,227.115l227.115-227.136c4.093-4.237,10.845-4.354,15.083-0.262 c4.237,4.093,4.354,10.845,0.262,15.083c-0.086,0.089-0.173,0.176-0.262,0.262L252.744,370.279 C250.748,372.281,248.039,373.408,245.213,373.415z'/%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A");
  width: 15px;
  height: 15px;
}

/* #recieve-updates:checked ~ label::before {
  background-color: green !important;
}

#recieve-updates:checked:focus ~ label::before {
  box-shadow: 0 0 0 1px var(--black), 0 0 0 0.2rem rgba(0, 255, 0, 0.25);
}
#recieve-updates:focus ~ label::before {
  box-shadow: 0 0 0 1px var(--black), 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}
#recieve-updates:active ~ label::before {
  background-color: #c8ffc8;
} */

/* .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--blue) !important;
  border-color: var(--blue) !important;
  width: 18px;
  height: 18px;
}

.custom-checkbox .custom-control-input ~ .custom-control-label::before {
  width: 18px;
  height: 18px;
}

.custom-checkbox .custom-control-input ~ .custom-control-label::after {
  width: 18px;
  height: 18px;
} */

/* .custom-control-label::before {
  width: 18px;
  height: 18px;
}

.custom-control-label::after {
  width: 18px;
  height: 18px;
} */

/* .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 8 8'%3e%3cpath fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e") !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0 c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7 C514.5,101.703,514.499,85.494,504.502,75.496z'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E") !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Capa_1' x='0px' y='0px' width='45.701px' height='45.7px' viewBox='0 0 45.701 45.7' style='enable-background:new 0 0 45.701 45.7;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M20.687,38.332c-2.072,2.072-5.434,2.072-7.505,0L1.554,26.704c-2.072-2.071-2.072-5.433,0-7.504 c2.071-2.072,5.433-2.072,7.505,0l6.928,6.927c0.523,0.522,1.372,0.522,1.896,0L36.642,7.368c2.071-2.072,5.433-2.072,7.505,0 c0.995,0.995,1.554,2.345,1.554,3.752c0,1.407-0.559,2.757-1.554,3.752L20.687,38.332z'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E") !important;
} */

/* .styled-checkbox {
  position: absolute;
  opacity: 0;
}
.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}
.styled-checkbox + label:before {
  content: '';
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 18px;
  height: 18px;
  background: white;
  border-radius: 3px;
}
.styled-checkbox:checked + label:before {
  background: var(--blue);
  border: solid 1px var(--blue);
}
.styled-checkbox:checked + label:after {
  content: '';
  position: absolute;
  left: 4px;
  top: 9px;
  background: black;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 black, 4px 0 0 black, 4px -2px 0 black, 4px -4px 0 black,
    4px -6px 0 black, 4px -8px 0 black;
  transform: rotate(45deg);
} */

.stream .swiper-container {
  margin-bottom: 5rem;
  padding-left: 120px;
}

.stream .swiper-container .swiper-slide img {
  width: 100%;
  /* height: 100%; */
  height: 300px;
  transition: 0.5s ease;
  cursor: pointer;
  /* object-fit: cover; */
}

.stream .swiper-container .swiper-slide img:hover {
  transform: scale(1.1);
  box-shadow: 0 0 1rem rgb(255 255 255 / 15%);
}

.swiss .custom-control-label::before {
  border-radius: 0.25rem;
}

.swiss
  .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: var(--blue);
  box-shadow: none;
}

.swiss
  .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before,
.swiss
  .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before {
  background-color: var(--blue);
  border-color: var(--blue);
}

.thumbnailShadow {
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.15);
}

.bg-Img {
  background-image: linear-gradient(
      176deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.1)
    ),
    url('../src/assets/images/Banner-01.jpg');
}

.swiss .swiss-navbar .bg-navbar {
  background-color: transparent;
  padding: 0.5rem 5.5rem;
}

.customSwiper .swiper-button-prev,
.customSwiper .swiper-button-next {
  color: var(--white) !important;
}
.customSwiper .swiper-pagination-bullet-active {
  background-color: var(--white) !important;
}
.customSwiper .swiper-pagination-bullet {
  background-color: var(--white) !important;
}

.customSwiper .swiper {
  width: 100%;
  height: 100%;
  background: var(--white);
}

.customSwiper .swiper-slide {
  font-size: 18px;
  color: var(--white);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* padding: 40px 60px; */
}

.customSwiper .parallax-bg {
  /* position: absolute; */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}
.customSwiper .simple-bg {
  /* position: absolute; */
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.customSwiper .swiper-slide .title {
  font-size: 41px;
  font-weight: 300;
  max-width: 600px;
  width: 100%;
}

.customSwiper .swiper-slide .subtitle {
  font-size: 21px;
}

.customSwiper .swiper-slide .text {
  font-size: 14px;
  max-width: 600px;
  width: 100%;
  line-height: 1.3;
}

.courseDetailBanner {
  min-height: 40vh;
  background-size: cover;

  /* background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)); */
  /* url('https://swiss-app-media.s3.amazonaws.com/course-images/c01f5c90-f0f6-4718-900b-d5483a25a328.jpg'); */
}

.textShadow {
  text-shadow: 2px 3px 2px var(--black);
}

.courseDetailList .list-group-item.active {
  z-index: 2;
  color: var(--white);
  background-color: var(--blue);
  border-color: var(--blue);
}
.courseDetailList .list-group-item {
  z-index: 2;
  color: var(--blue);
  background-color: #f0f1f2;
  border-color: #f0f1f2;
}
.courseDetailList .list-group-item:hover {
  z-index: 2;
  color: var(--white);
  background-color: var(--active-blue);
  border-color: var(--active-blue);
}
.courseDetailTabs a {
  color: var(--white);
}

.courseDetailTabs a:hover {
  color: var(--active-blue);
}

.courseDetailTabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--white) !important;
  background-color: var(--blue) !important;
  border-color: var(--white) !important;
}

.courseDetailTabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  color: var(--blue) !important;
  background-color: var(--white) !important;
  border-color: var(--blue) !important;
}

.my-customized-Card::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #212529; /* or add it to the track */
}

.my-customized-Card::-webkit-scrollbar-thumb {
  background: var(--blue);
}

.my-customized-Card .tab-content::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #212529; /* or add it to the track */
}

.my-customized-Card .tab-content::-webkit-scrollbar-thumb {
  background: var(--blue);
}

#quizAssignmentModal::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: var(--white); /* or add it to the track */
}

#quizAssignmentModal::-webkit-scrollbar-thumb {
  background: var(--blue);
}

#VideoContentModal::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: var(--white); /* or add it to the track */
}

#VideoContentModal::-webkit-scrollbar-thumb {
  background: var(--blue);
}

.my-customized-Card .tab-content {
  height: 90%;
  padding: 1rem;
  overflow-y: auto;
}

#customVideoModal .modal-content {
  height: 100%;
}
#customVideoModal {
  max-width: 100vw !important;
  height: 100vh !important;
  margin: 0;
  padding: 0;
}
#customVideoModal .modal-body {
  background-color: var(--white);
}

#customContentModal .modal-header {
  color: var(--black);
  background-color: var(--white);
}
#customContentModal .modal-body {
  background-color: var(--white);
}

#customContentModal .close {
  color: var(--black);
}

#paidModal .close {
  color: var(--black);
}
#paidModal .modal-body {
  background-color: var(--blue);
  color: var(--white);
}

#paidModal .modal-header {
  color: var(--white);
  background-color: var(--blue);
}
.paidModalImage {
  width: 50%;
}

.objectCover {
  object-fit: cover;
}

.customContentCard {
  transition: transform 0.8s;
}
.customContentCard .card-header {
  padding: 0;
  background-color: var(--black);
  border-bottom: 1px solid var(--blue) !important;
}

.customContentCard:hover {
  /* cursor: pointer; */
  transform: scale(1.05);
  /* z-index: 1111; */
}

.close {
  /* float: right;
  position: absolute; */
  right: 1rem;
  top: 1rem;
}

.heartIcon {
  color: #797979;
}
.heartIcon:hover {
  color: #797979;
}
.heartIconYellow {
  color: var(--active-blue);
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--white) !important;
  text-decoration: none;
  background-color: var(--blue) !important;
}
.dropdown-item a {
  color: var(--white) !important;
}
.dropdown-item a:active {
  color: var(--black) !important;
}
.dropdown-menu {
  right: 0 !important;
  left: auto !important;
}

.customContentCard {
  background-color: var(--black) !important;
  border: 1px solid var(--blue) !important;
}
.card_title a {
  color: var(--white) !important;
}
.card_title a:hover {
  color: var(--white) !important;
  text-decoration: underline !important;
}

.desktopOnly {
  height: 100vh;
  background-color: var(--blue);
  color: var(--white);
}

#customContentModal .modal-content p {
  font-size: 1.5rem !important;
}
#customVideoContentModal .modal-content p {
  font-size: 1rem !important;
}
.modal-header {
  align-items: center !important;
}

.desktopOnlyImageLogo {
  width: 20%;
}
.desktopOnlyImage {
  width: 100%;
}
.animate__animated.animate__fadeIn {
  --animate-duration: 700ms;
  --animate-delay: 1s;
}
.animate__animated.animate__zoomIn {
  --animate-duration: 1s;
  --animate-delay: 0.1s;
}
.animate__animated.animate__zoomOut {
  --animate-duration: 1s;
  --animate-delay: 0.1s;
}

@media (max-width: 1500px) {
  .swiss .subscribe h1 {
    font-size: 30px;
  }
}

@media (max-width: 1400px) {
  .swiss .hero .hero-content {
    width: 100%;
  }
}

@media (max-width: 1300px) {
  .swiss .swiss-navbar .navbar-nav .nav-link {
    margin-right: 60px;
  }
  .swiss .subscribe .input-group .input-group-prepend .input-group-text {
    padding: 0.375rem 1rem;
    font-size: 14px;
  }
  .swiss .footer .social-links a {
    margin-right: 0;
  }
  .swiss .footer .social-links img {
    margin-right: 25px;
  }
  .swiss .courses .hero-content p br {
    display: none;
  }
}

@media (max-width: 1200px) {
  .stream .swiper-container {
    padding-left: 0;
  }
  .swiss .swiss-navbar .navbar .navbar-collapse {
    padding-left: 0;
  }
  .swiss .stream .small-text {
    margin-top: 0;
  }
  .swiss .support .support-info {
    height: auto;
  }
  .swiss .support .support-info .info-form {
    max-width: 100%;
    position: relative;
    top: 0;
    border-radius: 0;
    border: 0;
  }
  .swiss .support .support-info .info-form .bd-r {
    border-right: 0;
  }
  .swiss .courses {
    padding-top: 30px;
    text-align: center;
  }
  .swiss .courses h2 {
    max-width: 450px;
    margin: 0 auto;
  }
  #paidModal h1 {
    font-size: 35px;
  }
  #paidModal h2 {
    font-size: 32px;
  }
  #paidModal h4 {
    font-size: 30px;
  }
  .paidModalImage {
    width: 60%;
  }
}

@media (max-width: 991px) {
  .swiss .swiss-navbar .bg-black {
    padding: 0.5rem 2rem;
  }
  .swiss .swiss-navbar .bg-navbar {
    background-color: transparent;
    padding: 0.5rem 2rem;
  }
  .swiss .hero {
    padding-left: 75px;
  }
  .swiss .hero .hero-content h1 {
    font-size: 2.5rem;
  }
  .swiss .hero .hero-content p {
    font-size: 18px;
  }
  .swiss .courses {
    padding-left: 70px;
  }
  .swiss .stream {
    padding: 50px 0;
  }
  .swiss .stream .courses-heading h1 {
    font-size: 2.5rem;
    margin-bottom: 3rem;
    margin-top: 3rem;
  }
  .swiss .stream .courses-heading h3 {
    font-size: 1.75rem;
  }
  .swiss .stream .courses-heading h1,
  .swiss .stream .courses-heading h3 {
    margin-left: 70px;
  }
  .swiss .subscribe input {
    height: 3rem;
  }
  .swiss .footer {
    padding-left: 50px;
    padding-right: 50px;
  }
  .swiss .footer .social-links img {
    margin-right: 20px;
  }
  .swiss .courses h2 {
    max-width: 100%;
  }
  #paidModal h1 {
    font-size: 30px;
  }
  #paidModal h2 {
    font-size: 27px;
  }
  #paidModal h4 {
    font-size: 25px;
  }
  .paidModalImage {
    width: 80%;
  }
}

@media (max-width: 767px) {
  .swiss .hero {
    padding-left: 2rem;
  }
  .desktopOnly h1 {
    font-size: 30px;
  }
  .desktopOnlyImage {
    width: 50px;
  }
}

@media (max-width: 768px) {
  .desktopOnlyImage {
    width: 500px;
  }
  .storeIcon {
    width: 250px;
  }
}

@media (max-width: 575px) {
  .swiss .hero {
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
  }
  .swiss .hero .hero-content h1 {
    font-size: 2rem;
  }
  .swiss .hero .hero-content p {
    width: 100%;
    font-size: 16px;
  }
  .swiss .courses {
    padding: 0 30px;
  }
  .swiss .courses .hero-content p br {
    display: none;
  }
  .swiss .stream .courses-heading h1 {
    font-size: 2rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .swiss .stream .courses-heading h3 {
    font-size: 1.5rem;
  }
  .swiss .stream .courses-heading h1,
  .swiss .stream .courses-heading h3 {
    margin-left: 30px;
  }
  .swiss .app,
  .swiss .subscribe {
    margin: 0;
  }
  .swiss .subscribe h1 {
    font-size: 25px;
  }
  .swiss .footer {
    padding-left: 15px;
    padding-right: 15px;
  }
  .swiss .footer h6 {
    margin-bottom: 1.5rem;
  }
  .single-course-modal.modal .modal-content .modal-body {
    padding: 0 40px 40px;
  }
  #paidModal h1 {
    font-size: 25px;
  }
  #paidModal h2 {
    font-size: 22px;
  }
  #paidModal h4 {
    font-size: 20px;
  }
  .paidModalImage {
    width: 100%;
  }
  .desktopOnlyImage {
    width: 400px;
  }
  .storeIcon {
    width: 250px;
  }
  .my-customized-Card .tab-content {
    height: 80%;
    padding: 1rem;
    overflow-y: auto;
  }
}
