:root {
  /* --blue: #1f79d2; */
  --blue: #1d3246;
  --white: #ffffff;
  --black: #000000;
  --active-blue: #427da5;
}

#header,
#footer {
  background-color: var(--white);
  padding: 0 5rem;
}

#footer {
  height: 146px;
}

#header img {
  width: 50px;
  margin-top: 5px;
}

.main-content .content-heading {
  background-color: var(--black);
  color: var(--white);
  padding: 0 5rem;
}

.main-content .content-heading h1 {
  padding-left: 50px;
  font-size: 60px;
  font-family: 'AvenirLight';
  line-height: 70px;
  margin-top: 57px;
  font-weight: 300;
  letter-spacing: -0.81px;
  margin-bottom: 57px;
}

.main-content .content-info {
  background-color: #161414;
  min-height: 900px;
  padding: 3rem 5rem 0 5rem;
}

.main-content .content-info h6 {
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 900;
  margin-bottom: 0;
  font-size: 25px;
  font-family: 'AvenirHeavy';
  line-height: 90px;
  letter-spacing: -0.34px;
  padding: 1.5rem 2.25rem;
}

.main-content .content-info p {
  margin: 0.75rem 0rem;
  padding: 0rem 2.25rem;
  word-break: break-word;
  font-family: 'AvenirMedium';
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -0.27px;
  font-weight: 500;
  color: var(--white);
}

.main-content .content-info a {
  color: var(--active-blue);
  font-size: 20px;
  font-family: 'AvenirHeavy';
  line-height: 29px;
  letter-spacing: -0.27px;
  transition: 0.3s;
  font-weight: 900;
}

.main-content .content-info a:hover {
  text-decoration: none;
}

.main-content .content-info hr {
  margin-top: 2rem;
}

.my-75 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.main-content .content-info .btn-outline-dark {
  color: var(--blue);
  /* border-color: #2d2d2d !important; */
  border-width: 2px;
  /* transition: 0.3s; */
  font-family: 'AvenirHeavy';
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 1px;
  font-weight: 900;
}

.main-content .content-info .btn-outline-dark:hover,
.main-content .content-info .btn-outline-dark:active {
  color: var(--black);
  background-color: var(--white);
  border-color: var(--white);
}

.main-content .content-info .btn-outline-dark:focus {
  outline: none;
  box-shadow: none !important;
}

@media (max-width: 767px) {
  #header,
  .main-content .content-heading {
    padding: 0 2rem;
  }

  .main-content .content-info {
    padding: 2rem 2rem 0rem 2rem;
  }

  .main-content .content-heading h1 {
    padding: 1.5rem 2rem;
  }

  .main-content .content-info h6 {
    padding: 1.5rem 1rem;
  }

  .main-content .content-info p {
    padding: 0 1rem;
  }
}

@media (max-width: 575px) {
  #header,
  .main-content .content-heading {
    padding: 0 1rem;
  }

  .main-content .content-info {
    padding: 1rem 1rem 0rem 1rem;
  }

  .main-content .content-heading h1 {
    padding: 1.5rem 1rem;
  }

  .main-content .content-info h6 {
    padding: 1rem 0;
  }

  .main-content .content-info p {
    padding: 0;
  }

  .account-label {
    font-size: 20px;
    font-family: 'AvenirMedium';
    line-height: 29px;
    letter-spacing: -0.27px;
  }
}

/* CSS for modals on my account */
.modal-label-text {
  font-family: 'AvenirMedium';
  font-size: 13px !important;
  line-height: 20px;
  letter-spacing: -0.81px;
  font-weight: 500;
  color: var(--black);
}

.modal-label-heavy-text {
  font-weight: 900;
  font-size: 15px !important;
  font-family: 'AvenirHeavy' !important;
  margin-left: 20px;
  margin-top: 20px;
}

.modal-heading {
  font-size: 27px;
  font-family: 'AvenirHeavy';
  line-height: 22px;
  letter-spacing: -0.36px;
}

.modal .modal-content {
  background-color: var(--white);
}

.modal .modal-header .close {
  font-size: 3rem;
  font-weight: 300;
}

.modal .modal-header .close:focus {
  outline: none;
}

.modal .myaccount-modal-body {
  width: 30%;
  height: auto;
  min-height: 500px;
  margin: 0 auto;
}

.modal form label {
  text-transform: uppercase;
  color: #606060;
  font-size: 12px;
  font-family: 'AvenirMedium';
  letter-spacing: -0.16px;
  font-weight: 500;
}

.modal input {
  background-color: #e2e2e2;
  border: 0;
  font-size: 15px;
  font-family: 'AvenirLight';
  /* color: var(--white); */
  letter-spacing: -0.2px;
  line-height: 22px;
}

.modal input:focus {
  box-shadow: none;
  outline: none;
  background-color: #e2e2e2;
}

.modal .modal-content p {
  font-size: 12px;
}

.modal .modal-content a {
  color: var(--blue);
  font-weight: 600;
}

.modal .modal-content a.gold {
  color: var(--blue);
}

.modal .modal-content a:hover {
  text-decoration: none;
  color: var(--black);
}

.btn-yellow {
  background-color: var(--blue);
  border-color: var(--blue);
  color: var(--white);
  font-family: 'AvenirHeavy';
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.53px;
  font-weight: 900;
  transition: 0.3s;
  text-transform: uppercase;
}

.btn-yellow:hover {
  background-color: transparent;
  border-color: var(--active-blue);
  color: var(--active-blue);
}

.btn-yellow:focus {
  outline: none;
  box-shadow: none;
}

/* New Billing day */
.modal select {
  width: 50%;
  height: 4rem;
  border: 1px solid #b9b9b9;
  background-color: transparent;
  border-radius: 5px;
  padding-left: 2rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.modal .bill-day-select {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='15' height='15' viewBox='0 0 490.688 490.688' style='enable-background:new 0 0 490.688 490.688;' xml:space='preserve'%3E%3Cpath style='fill:%23FFC107;' d='M472.328,120.529L245.213,347.665L18.098,120.529c-4.237-4.093-10.99-3.975-15.083,0.262 c-3.992,4.134-3.992,10.687,0,14.82l234.667,234.667c4.165,4.164,10.917,4.164,15.083,0l234.667-234.667 c4.237-4.093,4.354-10.845,0.262-15.083c-4.093-4.237-10.845-4.354-15.083-0.262c-0.089,0.086-0.176,0.173-0.262,0.262 L472.328,120.529z'/%3E%3Cpath d='M245.213,373.415c-2.831,0.005-5.548-1.115-7.552-3.115L2.994,135.633c-4.093-4.237-3.975-10.99,0.262-15.083 c4.134-3.992,10.687-3.992,14.82,0l227.136,227.115l227.115-227.136c4.093-4.237,10.845-4.354,15.083-0.262 c4.237,4.093,4.354,10.845,0.262,15.083c-0.086,0.089-0.173,0.176-0.262,0.262L252.744,370.279 C250.748,372.281,248.039,373.408,245.213,373.415z'/%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position-x: 40%;
  background-position-y: center;
}

.modal .modal-content .each-month {
  position: absolute;
  left: 55%;
  top: 50%;
  color: #9d9d9d;
  font-weight: 600;
}

/* New subscription plan */
#newPlan .plan-callout {
  padding: 0.25rem 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid transparent;
  border-left-width: 0.25rem;
  border-radius: 0.25rem;
  border-left-color: #d8d8d8;
}

#newPlan .plan-callout h5 {
  color: #9d9d9d;
  text-transform: uppercase;
  font-size: 12px;
}

#newPlan .sub-box {
  border-radius: 5px;
  border: 1px solid #797979;
  cursor: pointer;
}

#newPlan .sub-box div.d-flex {
  padding-left: 15px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

#newPlan .sub-box.active {
  border-color: var(--blue);
}

#newPlan .sub-notice {
  background-color: #797979;
  text-align: center;
  font-size: 12px;
  font-family: 'AvenirLight';
  line-height: 22px;
  letter-spacing: 0.4px;
  color: var(--black);
}

.custom-control-checkbox-text {
  padding: 12px 6px 15px 16px;
  border-radius: 4px;
  background-color: var(--white);
  color: var(--blue);
  align-items: center;
  display: flex;
  padding-left: 17px !important;
  padding-right: 24px;
  width: 100%;
  height: 68px;
}

.custom-control-input-plan {
  width: 24px !important;
  height: 24px !important;
}

.custom-control-label-plan {
  width: 100%;
}

.custom-control-label-plan span {
  font-size: 16px;
  font-family: 'AvenirMedium';
  letter-spacing: 0.53px;
  line-height: 22px;
}

.custom-control-label-plan::after {
  top: 50% !important;
  transform: translateY(-50%);
  width: 24px !important;
  height: 24px !important;
  cursor: pointer;
  border-radius: 30px !important;
}
.custom-control-label-plan::before {
  top: 50% !important;
  transform: translateY(-50%);
  width: 24px !important;
  height: 24px !important;
  cursor: pointer;
  background-color: #f2f2f2 !important;
  border-radius: 30px !important;
  border-color: #797979 !important;
}

.custom-control-input-plan:focus ~ .custom-control-label-plan::before {
  border-color: #797979 !important;
  box-shadow: none !important;
}

.custom-control-input-plan:checked ~ .custom-control-label-plan::before {
  background-color: var(--blue) !important;
  border-color: var(--blue) !important;
  box-shadow: none !important;
}

.custom-control-input-plan:checked ~ .custom-control-label-plan::after {
  /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 8 8'%3e%3cpath fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e") !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0 c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7 C514.5,101.703,514.499,85.494,504.502,75.496z'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E") !important; */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Capa_1' x='0px' y='0px' width='45.701px' height='45.7px' viewBox='0 0 45.701 45.7' style='enable-background:new 0 0 45.701 45.7;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M20.687,38.332c-2.072,2.072-5.434,2.072-7.505,0L1.554,26.704c-2.072-2.071-2.072-5.433,0-7.504 c2.071-2.072,5.433-2.072,7.505,0l6.928,6.927c0.523,0.522,1.372,0.522,1.896,0L36.642,7.368c2.071-2.072,5.433-2.072,7.505,0 c0.995,0.995,1.554,2.345,1.554,3.752c0,1.407-0.559,2.757-1.554,3.752L20.687,38.332z'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E") !important;
}

.custom-control.custom-checkbox {
  padding-left: 0;
}

label.custom-control-label-plan {
  position: relative;
  padding-right: 1.5rem;
}

label.custom-control-label-plan::before,
label.custom-control-label-plan::after {
  right: 0;
  left: auto !important;
}

/* Billing History */
#billHistory .modal-body {
  width: 80%;
  height: 500px;
  margin: 0 auto;
}

#billHistory .plan-callout {
  padding: 0.25rem 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid transparent;
  border-left-width: 9px;
  border-radius: 0.25rem;
  border-left-color: #d8d8d8;
}

#billHistory .plan-callout h5 {
  color: #9d9d9d;
  text-transform: uppercase;
  font-size: 12px;
}

#billHistory .table th {
  border-top: 0;
  text-transform: uppercase;
  color: #606060;
  font-weight: 500;
  font-family: 'AvenirMedium';
  font-size: 12px;
  letter-spacing: -0.16px;
  padding-left: 1rem;
  border-bottom: 1px solid #989494;
}

#billHistory .table td {
  font-size: 12px;
  font-family: 'AvenirMedium';
  color: black;
  letter-spacing: -0.16px;
  padding-left: 1rem;
}

#billHistory .table-responsive {
  max-height: 250px;
}

#billHistory label {
  text-transform: uppercase;
  color: #606060;
  font-size: 12px;
  font-family: 'AvenirMedium';
  letter-spacing: -0.16px;
  font-weight: 500;
}

/* Manage payment info */

/* For Stripe */
.card-number-light {
  background-color: #e2e2e2;
  padding: 10px 20px 11px;
  border-radius: 5px;
  margin-block-end: 5px;
  /* height: 50px; */
  width: 100%;
  font-size: 15px;
  font-family: 'AvenirLight';
  color: black;
  /* border: 1px solid #afafaf; */
  /* box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07); */
}
.next-button-pay {
  width: 100%;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 10px;
  background: var(--blue);
  padding: 9px 0px;
  border-radius: 4px;
  cursor: pointer;
  color: var(--white);
  font-family: 'AvenirHeavy';
}
.pay-button {
  padding: 0.7rem 2rem;
  width: 100%;
  margin: 1rem 0;
  color: white;
  font-weight: bold;
  font-size: medium;
  background-color: #556cd6;
  border: 0;
  border-radius: 5px;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  transition: box-shadow 500ms;
  cursor: pointer;
}
.pay-button:disabled {
  background-color: #afafaf;
  box-shadow: none;
  cursor: default;
}
.pay-button:disabled:hover {
  box-shadow: none;
}
.pay-button:hover {
  box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
}

.card-label {
  font-size: 12px;
  letter-spacing: -0.16px;
  color: white;
  font-family: 'AvenirMedium';
  margin-block-end: 5px;
  align-self: flex-start;
}

@media (max-width: 1200px) {
  .modal .myaccount-modal-body {
    width: 50%;
  }
  .modal select {
    width: 45%;
  }

  .modal .bill-day-select {
    background-position-x: 35%;
  }

  .modal .modal-content .each-month {
    left: 50%;
  }
}

@media (max-width: 991px) {
  .modal .myaccount-modal-body {
    width: 100%;
  }
  .modal select {
    width: 35%;
  }

  .modal .bill-day-select {
    background-position-x: 25%;
  }

  .modal .modal-content .each-month {
    left: 40%;
  }
}

@media (max-width: 575px) {
  .modal .modal-content .each-month {
    right: 0;
  }

  #billHistory .modal-content .modal-body {
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 400px) {
  .modal select {
    padding-left: 1rem;
  }
}

.form-control:focus {
  color: black;
}
