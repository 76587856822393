.logo img {
  width: 150px;
  /* height: 74px; */
}

/* .logo-big {
  width: 96px !important;
  height: 74px !important;
}

nav.navbar-login {
  background-color: black;
  color: white;
  padding: 0;
  justify-content: center;
}

.remove-caret {
  cursor: pointer;
}

.user-size li:hover {
  color: black;
  font-weight: bolder;
}

.user-size li {
  color: grey;
  font-weight: normal;
  border-bottom: 1px solid grey;
}

nav.navbar .dropdown-menu a {
  color: grey;
  font-weight: normal;
}

nav.navbar .dropdown-menu a:hover {
  color: black;
  font-weight: bolder;
  letter-spacing: 2px;
}

nav.navbar .user-size {
  min-width: 180px !important;
} */

/* .remove-caret:after {
    display: none;
} */
