/* Chrome, Safari, Edge, Opera */
#accEmail input::-webkit-outer-spin-button,
#accEmail input::-webkit-inner-spin-button,
#accEmailCheck input::-webkit-outer-spin-button,
#accEmailCheck input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#accEmail input[type='number'] {
  -moz-appearance: textfield;
  height: 87px;
  font-size: 50px;
  letter-spacing: 15px;
  /* padding: 0.375rem 1.75rem; */
  font-weight: 300;
  text-align: center;
  text-indent: 15px;
  caret-color: transparent;
  background-color: #1d3246;
  border: solid 1px #1d3246;
  color: white;
  font-family: 'AvenirLight';
}

#accEmailCheck input[type='number'] {
  -moz-appearance: textfield;
  height: 87px;
  font-size: 50px;
  letter-spacing: 15px;
  /* padding: 0.375rem 1.75rem; */
  font-weight: 300;
  text-align: center;
  text-indent: 15px;
  caret-color: transparent;
  background-color: #685b5b;
  /* border: solid 1px #a4a4a4; */
  color: black;
  font-family: 'AvenirLight';
}

/* @media (max-width: 500px) {
  #accEmail input[type='number'] {
    padding: 0.375rem 0.75rem;
    letter-spacing: 0.75rem;
  }
}

@media (max-width: 400px) {
  #accEmail input[type='number'] {
    letter-spacing: 0.25rem;
  }
} */
